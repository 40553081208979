import React from 'react' 
import { useParams, Link } from 'react-router-dom'

import { gql, useQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box' 
import Card from '@material-ui/core/Card' 
import CardContent from '@material-ui/core/CardContent' 
import Grid from '@material-ui/core/Grid' 
import Typography from '@material-ui/core/Typography' 

const query = gql`
  query GetPlan($planId: String!) {
    getPlan(planId: $planId) {
      name
      planId 
      description
      
      steps {
        stepId
        order
        name 
        description 
        stepImage 
      }
    }
  }
`;

const useStepStyles = makeStyles(theme => ({
  root: {
    maxWidth: 375,
    margin: theme.spacing(1),
    height: "90%"
  },
}))

const StepCard = ({step}) => {
  const classes = useStepStyles();
  return <Card className={classes.root}>
    <CardContent>
      <Typography variant="h5">{step.order} {step.name}</Typography>
      <Typography>{step.description}</Typography>
      <Typography>{step.stepImage}</Typography>
    </CardContent>
  </Card>
}

export function pluralize(name, count) {
  if(count === 1) {
    return name; 
  } else {
    return `${name}s`;
  }
}

const Plan = () => {
  const { id } = useParams()
  const { loading, error, data } = useQuery(query, {
    variables: { planId: id },
  });
  
  if(loading) {
  return <Box>
    <Typography>Loading...</Typography>
  </Box>
  }
  
  if(error) {
  return <Box>
    <Typography>Error for {id}:</Typography>
    <Typography>{JSON.stringify(error)}</Typography>
  </Box>
  }
  
  
  let steps = [...data.getPlan.steps].sort((a, b) => a.order - b.order);
  
  return <Box>
    <Link to="/">Back</Link>
    <Typography variant="h3">{data.getPlan.name}</Typography>
    <Typography>{data.getPlan.planId}</Typography>
    <a href={`https://links.manifestsurgery.com/plan/${data.getPlan.planId}`}>Share</a>
    
    <Typography variant="h4">{steps.length} {pluralize("Step", steps.length)}</Typography>
    <Grid container>
    
    {
      steps.map(step => {
        const key = `step_${step.stepId}`;
        return <Grid item xs={3} key={key}>
          <StepCard step={step} />
        </Grid>
      })
    }
    </Grid>
  </Box>
}

export default Plan 