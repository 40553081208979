import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import logo from './icon_167.png';
import ButtonBase from '@material-ui/core/ButtonBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuItemLink from './MenuItemLink';

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    flex: 1,
    textDecoration: 'none',
  },
  logoImage: {
    height: "2em",
    marginRight: "1em",
  }
}));

const Sidebar = (props) => {
  const { handleDrawerToggle, onSignOut, authenticated } = props;
  const classes = useStyles();

  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const handleProfileMenuClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const handleSignout = () => {
    onSignOut();
    handleProfileMenuClose();
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Hidden smUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Link to="/">
          <img src={logo} className={classes.logoImage} alt="logo" />
        </Link>
        <Typography component={Link} to="/" variant="h6" noWrap className={classes.logo} color="inherit" >
          Manifest Surgery
        </Typography>
        { authenticated && (
          <>
            <ButtonBase
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              color="primary"
              onClick={handleProfileMenuClick}
            >
              <Avatar />
            </ButtonBase>
            <Menu
              anchorEl={profileAnchorEl}
              open={Boolean(profileAnchorEl)}
              onClose={handleProfileMenuClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
              }}
              transitionDuration={0}
            >
              <MenuItemLink
                icon={<PersonIcon />}
                primary={'Your Profile'}
                to="/profile"
                onClick={handleProfileMenuClose}
              />
              <MenuItemLink
                icon={<SettingsIcon />}
                primary={'Settings'}
                to="/settings"
                onClick={handleProfileMenuClose}
              />
              <MenuItem onClick={handleSignout} button>
                <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                <ListItemText primary={'Sign Out'} />
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Sidebar;
