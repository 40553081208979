import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
  },
  link: {
    textDecoration: 'none',
  },
}));

const PlanCard = (props) => {
  const { plan } = props;
  const { planId, name, steps, description } = plan;
  const classes = useStyles();
  
  return (
    <Card variant="outlined" className={classes.card} key={plan.planId}>
      <CardActionArea>
        <Link to={`/plan/${planId}`} className={classes.link}>
          <CardContent>
            <Typography color="textPrimary" component="h2" variant="h5">
              {name}
            </Typography>
            <Typography color="textPrimary">
              {steps.length} Steps
            </Typography>
            <Typography color="textSecondary">
              {description}
            </Typography>
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  );
}

export default PlanCard;
