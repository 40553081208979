import React, { useState, useEffect, useCallback } from 'react';
import cognitoUtils from './utils/cognitoUtils';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import UnauthenticatedRoutes from './UnauthenticatedRoutes';
import { ApolloProvider } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles';
import Sidebar from './components/app/Sidebar';
import MainAppBar from './components/app/MainAppBar';
import Divider from '@material-ui/core/Divider';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Link } from 'react-router-dom';
import { Route } from 'react-router-dom';
import LandingPage from './views/LandingPage';
import { useDispatch } from 'react-redux';
import {
  setCurrentUser,
} from './slices/manifestSlice';
import { gql } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  main: {
    padding: theme.spacing(3),
  },
  signInButton: {
    width: '100%',
  },
}));

const App = (props) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [client, setClient] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [])

  useEffect(() => {
    cognitoUtils.createClient()
      .then(client => {
        setClient(client);
        return client;
      })
      .then((client) => {
        const query = gql`
        query CurrentUser {
          currentUser {
            name
            plans {
              planId
              name
              description
              steps {
                stepId
              }
            }
            followed {
              planId
              name
              description
              steps { 
                stepId
              }
            }
          }
        }`;
        return client.query({ query });
      })
      .then(result => {
        stableDispatch(setCurrentUser(result.data.currentUser));
        setAuthenticated(true);
      })
      .catch(() => {
        setAuthenticated(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [stableDispatch]);

  const onSignOut = () => {
    setClient(null);
    setAuthenticated(false);
    cognitoUtils.clearCookies();
  };

  return (
    <div className={classes.root}>
      <MainAppBar
        handleDrawerToggle={handleDrawerToggle}
        onSignOut={onSignOut}
        authenticated={authenticated}
      />
      { authenticated && (
        <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      )}
      <div className={classes.content}>
        <main className={classes.main}>
          <div className={classes.toolbar} />
          <UnauthenticatedRoutes />
          {
            !loading && (
              authenticated ? (
                <ApolloProvider client={client}>
                  <AuthenticatedRoutes />
                </ApolloProvider>
              ) : (
                <Route exact path="/" >
                  <LandingPage />
                </Route>
              )
            )
          }
        </main>
        <footer>
          <Divider />
          <BottomNavigation showLabels>
            <BottomNavigationAction component={Link} to="/terms-of-service" label="Terms of Service" />
            <BottomNavigationAction component={Link} to="/privacy-policy" label="Privacy Policy" />
            <BottomNavigationAction component={Link} to="/contact-us" label="Contact Us" />
          </BottomNavigation>
        </footer>
      </div>
    </div>
  );
}

export default App;
