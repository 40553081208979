import React from 'react';
import { Route } from 'react-router-dom';
import TermsOfService from './views/TermsOfService';
import PrivacyPolicy from './views/PrivacyPolicy';
import ContactUs from './views/ContactUs';

const UnauthenticatedRoutes = ({ client }) => (
  <>
    <Route exact path ="/terms-of-service">
      <TermsOfService />
    </Route>
    <Route exact path="/privacy-policy" >
      <PrivacyPolicy />
    </Route>
    <Route exact path="/contact-us" >
      <ContactUs />
    </Route>
  </>
);

export default UnauthenticatedRoutes;

