// This file comes from https://github.com/arronharden/cognito-demo-ui/blob/master/src/lib/cognitoUtils.js

import { CognitoAuth } from 'amazon-cognito-auth-js/dist/amazon-cognito-auth'
import { CognitoUserPool } from 'amazon-cognito-identity-js'
import { config as AWSConfig } from 'aws-sdk'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'universal-cookie';

const appConfig = {
  region: process.env.REACT_APP_REGION,
  userPool: process.env.REACT_APP_USER_POOL,
  userPoolBaseUri: process.env.REACT_APP_USER_POOL_BASE_URI,
  clientId: process.env.REACT_APP_CLIENT_ID,
  callbackUri: process.env.REACT_APP_CALLBACK_URI,
  signoutUri: process.env.REACT_APP_SIGNOUT_URI,
  apiUri: process.env.REACT_APP_API_URI
};

AWSConfig.region = appConfig.region

// Creates a CognitoAuth instance
const createCognitoAuth = () => {
  const appWebDomain = appConfig.userPoolBaseUri.replace('https://', '').replace('http://', '')
  const auth = new CognitoAuth({
    UserPoolId: appConfig.userPool,
    ClientId: appConfig.clientId,
    AppWebDomain: appWebDomain,
    TokenScopesArray: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin"
    ],
    RedirectUriSignIn: appConfig.callbackUri,
    RedirectUriSignOut: appConfig.signoutUri
  })
  return auth
}

// Creates a CognitoUser instance
const createCognitoUser = () => {
  const pool = createCognitoUserPool()
  return pool.getCurrentUser()
}

// Creates a CognitoUserPool instance
const createCognitoUserPool = () => new CognitoUserPool({
  UserPoolId: appConfig.userPool,
  ClientId: appConfig.clientId
})

// Get the URI of the hosted sign in screen
const getCognitoSignInUri = () => {
  const signinUri = `${appConfig.userPoolBaseUri}/login?response_type=code&client_id=${appConfig.clientId}&redirect_uri=${appConfig.callbackUri}`
  return signinUri
}

// Parse the response from a Cognito callback URI (assumed a token or code is in the supplied href). Returns a promise.
const parseCognitoWebResponse = (href) => {
  return new Promise((resolve, reject) => {
    const auth = createCognitoAuth()

    // userHandler will trigger the promise
    auth.userhandler = {
      onSuccess: function (result) {
        resolve(result)
      },
      onFailure: function (err) {
        reject(new Error('Failure parsing Cognito web response: ' + err))
      }
    }
    auth.parseCognitoWebResponse(href)
  })
}

// Gets a new Cognito session. Returns a promise.
const getCognitoSession = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = createCognitoUser()
    cognitoUser.getSession((err, result) => {
      if (err || !result) {
        reject(new Error('Failure getting Cognito session: ' + err))
        return
      }

      // Resolve the promise with the session credentials
      console.debug('Successfully got session: ' + JSON.stringify(result))
      const session = {
        credentials: {
          accessToken: result.accessToken.jwtToken,
          idToken: result.idToken.jwtToken,
          refreshToken: result.refreshToken.token
        },
        user: {
          userName: result.idToken.payload['cognito:username'],
          email: result.idToken.payload.email
        }
      }
      resolve(session)
    })
  })
}

// Refreshes the Cognito session. Returns a promise.
const refreshCognitoSession = (refreshToken) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = createCognitoUser()
    cognitoUser.refreshSession({getToken: () => refreshToken}, (err, result) => {
      if (err || !result) {
        reject(new Error('Failure refreshing Cognito session: ' + err))
        return
      }

      // Resolve the promise with the session credentials
      console.debug('Successfully got session: ' + JSON.stringify(result))
      const session = {
        credentials: {
          accessToken: result.accessToken.jwtToken,
          idToken: result.idToken.jwtToken,
          refreshToken: result.refreshToken.token
        },
        user: {
          userName: result.idToken.payload['cognito:username'],
          email: result.idToken.payload.email
        }
      }

      resolve(session);
    })
  })
}

// Sign out of the current session (will redirect to signout URI)
const signOutCognitoSession = () => {
  const auth = createCognitoAuth()
  auth.signOut()
}

const createClient = async () => {
  const cookies = new Cookies();
  const refreshToken = cookies.get('refreshToken');
  let session = null;
  if (refreshToken) {
    session = await refreshCognitoSession(refreshToken);
  }
  if (session == null && window.location.search) {
    await parseCognitoWebResponse(window.location.href);
    session = await getCognitoSession();
  }
  if (session !== null) {
    const httpLink = createHttpLink({
      uri: `${appConfig.apiUri}/graphql`,
    });

    const authLink = setContext((_, { headers }) => {
      const token = session.credentials.idToken;
      return {
        headers: {
          ...headers,
          authorization: token ? `${token}` : "",
        }
      }
    });

    cookies.set('refreshToken', session.credentials.refreshToken);

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
    });
  }
  throw new Error('Failed to authenticate');
}

const clearCookies = () => {
  const cookies = new Cookies();
  cookies.remove('refreshToken');
};

export default {
  getCognitoSignInUri,
  signOutCognitoSession,
  createClient,
  clearCookies
}
