import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'manifest',
  initialState: {
    apolloClient: null,
    currentUser: null,
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = {
        name: action.payload.name,
        plans: action.payload.plans,
        followedPlans: action.payload.followed,
      };
    },
  },
});

export const { setCurrentUser } = slice.actions;

export const getCurrentUser = state => state.manifest.currentUser;

export default slice.reducer;
