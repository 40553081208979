import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import HomeIcon from '@material-ui/icons/Home';
import ListItemLink from './ListItemLink';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
}));

const Sidebar = (props) => {
  const { mobileOpen, handleDrawerToggle, window } = props;
  const classes = useStyles();


  const drawer = (onClick) => (
    <div className={classes.drawerContainer}>
      <List>
        <ListItemLink
          icon={<HomeIcon />}
          primary={'Home'}
          to="/"
          onClick={onClick}
        />
        <ListItemLink
          icon={<FormatListNumberedIcon />}
          primary={'Your Plans'}
          to="/your-plans"
          onClick={onClick}
        />
        <ListItemLink
          icon={<FormatListNumberedIcon />}
          primary={'Followed Plans'}
          to="/followed-plans"
          onClick={onClick}
        />
        <ListItemLink
          icon={<MeetingRoomIcon />}
          primary={'Operating Room'}
          to="/operating-room"
          onClick={onClick}
        />
      </List>
    </div>
  );
  
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer(handleDrawerToggle)}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <div className={classes.toolbar} />
          {drawer(() => {})}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default Sidebar;
