import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../slices/manifestSlice';
import PlanCard from '../../components/app/PlanCard';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(3)
  }
}));

const Home = () => { 
    const currentUser = useSelector(getCurrentUser);
    const classes = useStyles();
  
    return (
      <>
        <Typography component="h1" variant="h4" gutterBottom>
          Home
        </Typography>
        
        <Divider className={classes.divider} />
 
        <Typography component="h2" variant="h5" gutterBottom>
          Your Plans
        </Typography>

        <Typography>User: {currentUser?.name}</Typography>
  
        { currentUser?.plans?.map(plan => (
          <PlanCard key={`plan_${plan.planId}`} plan={plan} />
        ))}
        
        <Divider className={classes.divider} />
        
        <Typography component="h2" variant="h5" gutterBottom>
          Followed Plans
        </Typography>
  
        {currentUser?.followedPlans?.map(plan => (
          <PlanCard key={`plan_${plan.planId}`} plan={plan} />
        ))}
      </>
    );
}

export default Home;
