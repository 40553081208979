import React from 'react'
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../slices/manifestSlice';
import PlanCard from '../../components/app/PlanCard';

const FollowedPlans = () => { 
  const currentUser = useSelector(getCurrentUser);

  return (
    <>
      <Typography component="h1" variant="h4" gutterBottom>
        Followed Plans
      </Typography>

      {currentUser.followedPlans.map(plan => (
        <PlanCard plan={plan} />
      ))}
    </>
  );
}

export default FollowedPlans;
