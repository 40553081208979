import React from 'react';
import { Route } from 'react-router-dom';
import Home from './views/home/Home'
import Plan from './views/plan/Plan.js'
import OperatingRoom from './views/operating_room/OperatingRoom.js'
import YourPlans from './views/your_plans/YourPlans.js';
import FollowedPlans from './views/followed_plans/FollowedPlans.js';
import Profile from './views/Profile';
import Settings from './views/Settings';

const AuthenticatedRoutes = () => (
  <>
    <Route
      exact path ="/"
      render={(routeProps ) =>
        <Home {...routeProps} />
      }
    />
    <Route exact path="/operating-room" >
      <OperatingRoom />
    </Route>
    <Route exact path="/your-plans">
      <YourPlans />
    </Route>
    <Route exact path="/followed-plans">
      <FollowedPlans />
    </Route>
    <Route
      path ="/plan/:id"
      children={<Plan />}
    />
    <Route exact path="/profile" >
      <Profile />
    </Route>
    <Route exact path="/settings" >
      <Settings />
    </Route>
  </>
);

export default AuthenticatedRoutes;

