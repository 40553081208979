import React from 'react'
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../slices/manifestSlice';

const Profile = () => {
  const currentUser = useSelector(getCurrentUser);

  return (
    <>
      <Typography component="h1" variant="h4" gutterBottom>Your Profile</Typography>
      <Typography>Name: {currentUser.name}</Typography>
    </>
  );
}

export default Profile;
